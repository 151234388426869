export const DealReturnMetricKey = {
  IRR: 'IRR',
  EquityMultiple: 'EquityMultiple',
  YieldOnCost: 'YieldOnCost',
  CashOnCash: 'CashOnCash',
};

export const DealReturnMetric = {
  IRR: {
    label: 'Internal Rate of Return (IRR)',
    unit: '%',
  },
  EquityMultiple: {
    label: 'Equity Multiple',
    unit: 'x',
  },
  YieldOnCost: {
    label: 'Yield on Cost at Stabilization',
    unit: '%',
  },
  CashOnCash: {
    label: 'Avg. Annual Cash-on-Cash',
    unit: '%',
  },
};

export const EMPTY_EXP_RETURNS = {
  [DealReturnMetricKey.IRR]: 0,
  [DealReturnMetricKey.CashOnCash]: 0,
  customMetrics: [
    { label: 'Equity Multiple', value: 0.0, unit: 'x' },
    { label: 'Yield on Cost at Stabilization', value: 0.0, unit: '%' },
  ],
};
