import {
  Box,
  Container,
  Paper,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  useMarkMessageAsRead,
  useMessageThreads,
  useReplyToMessageThread,
} from '../../components/hooks/messages/use-messages';
import CircularProgress from '@mui/material/CircularProgress';
import { neutral } from '../../components/theme/colors';
import { useSessionUser } from '../../components/hooks/session/useSessionUser';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { MessageThreadView } from '../../components/messages/message-thread-view';
import { MessageThreadListItem } from '../../components/messages/message-thread-list-item';

export default function Messages() {
  const { sessionUser } = useSessionUser();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const externalThreadId = queryParams.get('tid');

  const { data: threads, isLoading } = useMessageThreads({});
  const markMessageRead = useMarkMessageAsRead();
  const replyToMessageThread = useReplyToMessageThread();

  const [selectedThread, setSelectedThread] = useState(null);

  const markMessageThreadAsRead = (threadToMark) => {
    const latestMessage = selectedThread
      ? selectedThread.messages[selectedThread.messages.length - 1]
      : null;
    const isRead = latestMessage.reads?.length > 0;
    if (!isRead && !markMessageRead.isLoading) {
      markMessageRead.mutate({
        threadId: threadToMark.id,
        messageId: latestMessage.id,
      });
    }
  };

  useEffect(() => {
    if (selectedThread) {
      markMessageThreadAsRead(selectedThread);
    }
  }, [selectedThread]);

  useEffect(() => {
    if (threads && selectedThread === null) {
      const newSelectedThread = externalThreadId
        ? threads.find(
            (t) =>
              t.externalId === externalThreadId || t.id === externalThreadId,
          )
        : threads[0];

      setSelectedThread(newSelectedThread);
    }
  }, [threads]);

  const handleMessageThreadClick = ({ thread }) => {
    if (thread.id !== selectedThread?.id) {
      setSelectedThread(thread);
      const toUrl = '?tid=' + thread.externalId;
      navigate(toUrl);
    }
  };

  const handleReply = ({ replyThreadId, message }) => {
    replyToMessageThread.mutate({
      threadId: replyThreadId,
      message,
      fromUser: sessionUser,
    });

    setSelectedThread((prev) => {
      const newThread = { ...prev };
      newThread.messages.push({
        id: uuid(),
        threadId: replyThreadId,
        fromUser: sessionUser,
        body: message,
        createdAt: new Date().toISOString(),
        reads: [
          {
            readAt: new Date().toISOString(),
          },
        ],
      });
      return newThread;
    });
  };

  return (
    <Container maxWidth="xl">
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4">Messages</Typography>
        {isLoading || !threads ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : !threads.length ? (
          <Grid container sx={{ mt: 2 }}>
            <Grid xs={12}>
              <Typography variant="body1">No messages yet</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            sx={{
              mt: 2,
              borderTop: '1px solid ' + neutral[200],
            }}
          >
            <Grid
              xs={5}
              sx={{
                maxHeight: '80vh',
                overflow: 'auto',
                borderRight: '1px solid ' + neutral[200],
              }}
            >
              {threads.map((thread, idx) => (
                <Box
                  key={idx}
                  sx={{
                    pt: 2,
                    borderBottom: '1px solid ' + neutral[200],
                  }}
                >
                  <MessageThreadListItem
                    thread={thread}
                    deal={thread.deal}
                    organization={thread.organization}
                    isSelected={selectedThread?.id === thread.id}
                    onClick={handleMessageThreadClick}
                  />
                </Box>
              ))}
            </Grid>
            <Grid xs={7} style={{ maxHeight: '90vh', overflow: 'auto' }}>
              {selectedThread && (
                <MessageThreadView
                  thread={selectedThread}
                  onReply={handleReply}
                  isReplySending={replyToMessageThread.isLoading}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
    </Container>
  );
}
