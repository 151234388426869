import {
  Box,
  Button,
  Container,
  debounce,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useJsApiLoader } from '@react-google-maps/api';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Role } from '../../../components/enums/role.enum';
import { DealType } from '../../../components/enums/deal-type.enum';
import { PlacesLibraries } from '../../../components/maps/google-maps-utils';
import { useCreateDeal } from '../../../components/hooks/deals/use-deal';
import {
  useOrganization,
  useOrganizations,
} from '../../../components/hooks/organizations/use-organizations';
import { DEFAULT_TIMELINE } from '../../../helpers/timeline';
import { DEFAULT_CAP_STRUCTURE } from '../../../helpers/cap-structure';
import { EMPTY_EXP_RETURNS } from '../../../helpers/exp-returns';
import { useSessionUser } from '../../../components/hooks/session/useSessionUser';

export default function CreateDeal() {
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const isSysAdmin = sessionUser?.role === Role.SysAdmin;

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const createDeal = useCreateDeal();
  const { data: organizations } = useOrganizations({
    options: {
      enabled: isSysAdmin,
    },
  });

  const { data: organization } = useOrganization({
    id: sessionUser?.orgId,
    options: {
      enabled: !!sessionUser?.orgId,
    },
  });

  useEffect(() => {
    if (!isSysAdmin && organization && !organization.approvedAt) {
      navigate('/deals');
    }
  }, [organization, isSysAdmin]);

  const { isLoaded: isGoogleLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: PlacesLibraries,
  });

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSubmit = async (values) => {
    setIsSubmitClicked(true);
    const newDeal = await createDeal.mutateAsync({
      createDealRequest: { ...values },
      user: sessionUser,
    });
    navigate('/deals/' + newDeal.id + '/edit?backTo=/deals&isNew=true');
  };

  const initialValues = {
    orgId: sessionUser.orgId || '',
    name: '',
    type: DealType.Asset,
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    latlng: null,
    submit: null,
  };

  const validationShape = {
    orgId: Yup.number().required('Organization is required'),
    name: Yup.string().when('type', {
      is: (type) => type !== DealType.Fund,
      then: () =>
        Yup.string()
          .max(255, 'Asset Name must be at most 255 characters')
          .required('Asset Name is required'),
      otherwise: () =>
        Yup.string()
          .max(255, 'Fund Name must be at most 255 characters')
          .required('Fund Name is required'),
    }),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: true,
    onSubmit: async (values, helpers) => {
      const request = {
        ...values,
        timeline: JSON.stringify(DEFAULT_TIMELINE),
        capStructure: JSON.stringify(DEFAULT_CAP_STRUCTURE),
        expReturns: JSON.stringify(EMPTY_EXP_RETURNS),
      };
      if (values.type === DealType.Fund && organization) {
        request.address1 = organization.address1;
        request.address2 = organization.address2;
        request.city = organization.city;
        request.state = organization.state;
        request.zip = organization.zip;
        request.latlng = organization.latlng;
      }

      try {
        await handleSubmit(request);
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleDealTypeChange = async (e) => {
    await formik.setValues(
      {
        ...formik.values,
        type: e.target.value,
        latlng: null,
      },
      true,
    );
  };

  const debouncedValidate = useMemo(
    () => debounce(formik.validateForm, 250),
    [formik.validateForm],
  );

  useEffect(() => {
    debouncedValidate(formik.values);
  }, [formik.values, debouncedValidate]);

  return isSubmitClicked ? (
    <Stack alignItems="center" sx={{ mt: 10 }} rowGap={2}>
      <Typography variant={'h6'}>Creating your new Deal</Typography>
      <CircularProgress />
    </Stack>
  ) : (
    <Container maxWidth="md">
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Stack spacing={1}>
            <Typography variant="h4">Create New Deal</Typography>
            <Typography variant="body2" color="text.secondary">
              Enter the following required information about your deal
            </Typography>
          </Stack>
          <div>
            <Button onClick={handleCancelClick} color={'error'}>
              Cancel
            </Button>
          </div>
        </Stack>

        <form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Box>
            <Grid container spacing={3}>
              {isSysAdmin && (
                <Grid xs={12}>
                  <TextField
                    error={!!(formik.touched.orgId && formik.errors.orgId)}
                    fullWidth
                    required
                    helperText={formik.touched.orgId && formik.errors.orgId}
                    label="Organization"
                    name="orgId"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.orgId}
                    select
                  >
                    <MenuItem value="">Select Organization</MenuItem>
                    {organizations?.map((org) => (
                      <MenuItem key={org.id} value={org.id}>
                        {org.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid xs={12} md={12}>
                <TextField
                  error={!!(formik.touched.type && formik.errors.type)}
                  fullWidth
                  required
                  helperText={
                    (formik.touched.type && formik.errors.type) ||
                    formik.values.type === DealType.Asset
                      ? 'A real estate property for investment'
                      : formik.values.type === DealType.Sale
                        ? 'A real estate or land property for sale'
                        : 'A fund making more than 1 real estate investment'
                  }
                  label="Deal Type"
                  name="type"
                  onBlur={formik.handleBlur}
                  onChange={handleDealTypeChange}
                  value={formik.values.type}
                  select
                >
                  <MenuItem value="Asset">Single Asset Investment</MenuItem>
                  <MenuItem value="Fund">
                    Fund (Multi-Asset Investment)
                  </MenuItem>
                  {/*<MenuItem value="Sale">Property for Sale to Sponsor</MenuItem>*/}
                </TextField>
              </Grid>
              <Grid xs={12} md={12}>
                <TextField
                  error={!!(formik.touched.name && formik.errors.name)}
                  fullWidth
                  required
                  helperText={formik.touched.name && formik.errors.name}
                  label={
                    formik.values.type !== DealType.Fund
                      ? 'Asset Name'
                      : 'Fund Name'
                  }
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </Grid>
            </Grid>
          </Box>
          {formik.errors.submit && (
            <Typography color="error" sx={{ mt: 3 }} variant="body2">
              {formik.errors.submit}
            </Typography>
          )}
          <Button
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
            disabled={
              !formik.isValid || formik.isValidating || formik.isSubmitting
            }
          >
            {formik.isSubmitting ? 'Creating new deal...' : 'Create Deal'}
          </Button>
        </form>
      </Stack>
    </Container>
  );
}
