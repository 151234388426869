import { Button, Stack, Table, TableBody, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Edit from '@mui/icons-material/Edit';
import {
  DealReturnMetric,
  DealReturnMetricKey,
  EMPTY_EXP_RETURNS,
} from '../../../helpers/exp-returns';
import DealEditExpReturnsDialog from '../../dialogs/deal-edit-exp-returns-dialog';
import { ExpReturnRow } from '../view/deal-exp-returns-row';
import { SectionHeader } from '../../util/section-header';

export const EditExpReturns = ({
  initialValue,
  onChange,
  readOnly,
  isFund,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [form, setForm] = useState(
    initialValue ? JSON.parse(initialValue) : EMPTY_EXP_RETURNS,
  );

  const handleChange = () => {
    onChange(JSON.stringify(form));
  };

  useEffect(() => {
    handleChange();
  }, [form]);

  useEffect(() => {
    setForm(initialValue ? JSON.parse(initialValue) : EMPTY_EXP_RETURNS);
  }, [initialValue]);

  const handleRestoreDefault = () => {
    setForm(EMPTY_EXP_RETURNS);
  };

  const openEdit = () => {
    setShowEdit(true);
  };

  const closeEdit = () => {
    setShowEdit(false);
  };

  const handleSaveExpReturns = (newValues) => {
    setForm(newValues);
    closeEdit();
  };

  return (
    <>
      <DealEditExpReturnsDialog
        open={showEdit}
        metrics={form}
        onClose={closeEdit}
        onSubmit={handleSaveExpReturns}
        isFund={isFund}
      />
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <SectionHeader
          title={'Expected Returns'}
          subtitle={`The expected return metrics for the ${
            isFund ? 'fund' : 'project'
          }`}
        />
        {!readOnly && (
          <Stack direction="row" alignItems={'center'}>
            <Tooltip title={`Add or edit the return metrics`}>
              <Button size={'small'} onClick={openEdit} startIcon={<Edit />}>
                Add / Edit Metrics
              </Button>
            </Tooltip>
          </Stack>
        )}
      </Stack>
      <Stack sx={{ mt: 1 }}>
        <Table padding="none" size={'small'}>
          <TableBody>
            {form[DealReturnMetricKey.IRR] !== '' && (
              <ExpReturnRow
                label={DealReturnMetric[DealReturnMetricKey.IRR].label}
                value={form[DealReturnMetricKey.IRR]}
                unit={DealReturnMetric[DealReturnMetricKey.IRR].unit}
              />
            )}
            {form[DealReturnMetricKey.CashOnCash] !== '' && (
              <ExpReturnRow
                label={DealReturnMetric[DealReturnMetricKey.CashOnCash].label}
                value={form[DealReturnMetricKey.CashOnCash]}
                unit={DealReturnMetric[DealReturnMetricKey.CashOnCash].unit}
              />
            )}
            {form.customMetrics?.map((metric, index) => (
              <ExpReturnRow
                key={index}
                label={metric.label}
                value={metric.value}
                unit={metric.unit}
              />
            ))}
          </TableBody>
        </Table>
      </Stack>
    </>
  );
};
